import React from "react";
import SubHeader from "../customComponents/SubHeader";
import LoyaltyPointsForm from "./LoyaltyPointsForm";
import Footer from "../customComponents/Footer";


const LoyaltyPoints = () => {


  return (
    <>
      <SubHeader text={"Edit customer balance"} />
    <div className="w-[1100px] mx-auto">
      <div className="w-full mx-auto rounded-[10px] mt-16  shadow-custom-dark">
        <div className="w-full flex h-[500px]">
          <div className="flex w-[35%] items-center bg-white rounded-l-[10px]">
            <img
              className="w-full mx-auto rounded-l-[10px]"
              src="/forms/formbg.jpg"
              alt=""
            />
          </div>
          <div className="w-[65%] py-6 px-6">
             <LoyaltyPointsForm 
             header={'Modify loyalty points'}
             searchLabel = {'Search customer Mob no'}
             />
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default LoyaltyPoints;
