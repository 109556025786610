import React from 'react'
import { useDispatch } from 'react-redux'
import SubHeader from '../customComponents/SubHeader';
import Footer from '../customComponents/Footer';

const Dashboard = () => {

  const dispatch = useDispatch();

  return (
    <div className='mx-auto w-full'>
   <SubHeader text={'Dashboard'}/>
   <Footer/>
    </div>
  )
}

export default Dashboard
