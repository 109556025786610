import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { clearAlert } from '../../redux/alertSlice';

const AlertBox = () => {
  const [alert, setAlert] = useState(false);
  const { status, type, message } = useSelector((store) => store.alert);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status) {
      setAlert(true);
      const timer = setTimeout(() => {
        dispatch(clearAlert());
        setAlert(false);
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timer on unmount or before re-running the effect
    }
  }, [status, dispatch]); // Dependency array should include 'status' to run the effect when 'status' changes

  return (
    <div className='absolute bottom-2 left-2 z-50'>
      {alert && <Alert variant='filled' severity={type}>{message}</Alert>}
    </div>
  );
};

export default AlertBox;
