import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const SideMenu = () => {
  const [id, setId] = useState(0);
  const navigate = useNavigate();

  return (
    <div className="relative w-full h-full font-mont px-2 bg-primary text-white shadow-custom-medium">

      <div className="font-semibold px-3 pt-4 text-[22px]">Jaina Jewellers</div>

      <div className="mt-8 font-mont font-semibold">
        <div
          className="mb-2"
          onClick={() => {
            setId(0);
          }}
        >
          <Link to={"/home"}>
            <div
              className={`flex items-center px-3 py-2 text-sm rounded-[10px] mb-4
                cursor-pointer hover:bg-second/30 transition-all duration-200 ${
                id === 0 ? "bg-second text-primary" : "text-fourth"
              }`}
            >
              <div className={`mr-3`}>
                <DashboardIcon />
              </div>
              <p>Dashboard</p>
            </div>
          </Link>
        </div>

        <div
          className="mb-2"
          onClick={() => {
            setId(1);
          }}
        >
          <Link to={"/home/mobile-app"}>
            <div
              className={`flex items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200 ${
                id === 1 && "bg-second text-primary"
              }`}
            >
              <div className={`mr-3`}>
                <PhonelinkSetupOutlinedIcon />
              </div>
              <p>Mobile</p>
            </div>
          </Link>
        </div>

        <div
          className="mb-2"
          onClick={() => {
            setId(2);
          }}
        >
          <Link to={"/home/loyalty-points"}>
            <div
              className={`flex items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200 ${
                id === 2 && "bg-second text-primary"
              }`}
            >
              <div className={`mr-3`}>
                <AutoAwesomeOutlinedIcon />
              </div>
              <p>Loyalty Points</p>
            </div>
          </Link>
        </div>

        <div
          className="mb-2"
          onClick={() => {
            setId(3);
          }}
        >
          <Link to={"/home/statement"}>
            <div
              className={`flex items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200 ${
                id === 3 && "bg-second text-primary"
              }`}
            >
              <div className={`mr-3`}>
                <LibraryBooksOutlinedIcon />
              </div>
              <p>Statement</p>
            </div>
          </Link>
        </div>

        <div
          className="mb-2"
          onClick={() => {
            setId(4);
          }}
        >
          <Link to={"/home/user"}>
            <div
              className={`hidden flex items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200 ${
                id === 4 && "bg-second text-primary"
              }`}
            >
              <div className={`mr-3`}>
                <ManageAccountsOutlinedIcon />
              </div>
              <p>User</p>
            </div>
          </Link>
        </div>

        <div
          onClick={() => {
            localStorage.removeItem('accessToken');
            navigate("/");
          }}
          className={`flex items-center px-3 py-2 text-sm rounded-[10px] cursor-pointer hover:bg-second/30 transition-all duration-200`}
        >
          <div className={`mr-3`}>
            <LogoutOutlinedIcon />
          </div>
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
